<div *ngIf="property && entityData" id="print-page">
    <div class="page">
        <div id="Table_01" class="boxWaper">
            <div class="content-pdf">
                <div *ngIf="entityData?.logo" id="logo">
                    <span class="wrap">
                        <img [src]="entityData?.logo" style="max-height: 120px;" />
                    </span>
                </div>
                <div id="contactos">
                    <span *ngIf="entityData?.name" class="nome">
                        {{entityData?.name}}
                        <br />
                    </span>
                    <span class="textcont">

                        <span *ngIf="entityData?.address" class="morada">{{entityData?.address}}
                        </span>

                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center" *ngIf="entityData?.cellphoneNumber">
                                <ion-icon class="me-1" src="assets/new-icons/call-outline.svg"></ion-icon>
                                <span class="telemovel mb-0">{{entityData?.cellphoneNumber}}</span>
                            </div>
                            <div class="d-flex align-items-center" *ngIf="entityData?.email">
                                <ion-icon class="me-1" src="assets/new-icons/mail-outline.svg"></ion-icon>
                                <span class="email">
                                    <a href="mailto:{{entityData?.email}}" style="text-decoration: none;">
                                        <span color="#1A1A1A">{{entityData?.email}}</span>
                                    </a>
                                </span>
                            </div>
                            <div class="d-flex align-items-center" *ngIf="entityData?.license">
                                <ion-icon class="me-1" src="assets/new-icons/information-circle-outline.svg"></ion-icon>
                                <span class="AMI">AMI: {{entityData?.license}}</span>
                            </div>
                        </div>
                    </span>
                </div>
                <div class="septop"></div>
            </div>
            <div class="content-pdf fotos">
                <ng-container *ngFor="let img of property?.media; let i = index">
                    <img *ngIf="i < 3" [src]="img" class="thumb" />
                </ng-container>
            </div>

            <div class="content-pdf">
                <div class="title-box">
                    <span *ngIf="property?.title" class="titleImo">
                        {{property?.title}}
                    </span>
                    <span *ngIf="property?.district" class="loca">{{property?.district}}</span>
                </div>
                <div class="box30">

                    <ul class="businessList">
                        <li id="bus1">
                            <span *ngIf="property.businessType" class="label soum">{{property?.businessType |
                                translate}}</span>
                            <span class="value">{{property?.price != 0 && property?.price != null && property?.publishPrice ?
                                '€ '+priceFormat(property?.price) :
                                't-on-request' | translate}}</span>
                        </li>
                    </ul>

                    <span class="business ref"><b>Ref:</b> {{property?.reference}}</span>
                </div>
            </div>

            <div class="content-pdf">
                <div class="tituloDes detalhes">{{'t-details' | translate}}</div>
                <ul class="textodet">


                    <li *ngIf="property?.propertyState"><span>{{'FILTERS.PROPERTY-STATE.TEXT' | translate}}:
                        </span>{{property?.propertyState |
                        translate}} </li>
                    <li *ngIf="property?.propertyType"><span>{{'t-propertyType' | translate}}:
                        </span>{{property?.propertyType}} </li>
                    <li *ngIf="property?.businessType"><span>{{'t-businessType' | translate}}:
                        </span>{{property?.businessType | translate}} </li>

                </ul>
            </div>
            <div *ngIf="property.description != ''" class="content-pdf">
                <div class="tituloDes">
                    {{'t-description' | translate}}

                </div>
                <div class="textoDes"><span [innerHTML]="property?.description"></span></div>
            </div>
            <div *ngIf="property?.divisions?.length > 0" class="content-pdf">
                <div class="tituloDes detalhes">{{'t-characteristics' | translate}}</div>
                <ul class="textodet">
                    <ng-container *ngFor="let division of property?.divisions">
                        <li *ngIf="division.tag == 'bedrooms' && division.quantity != 0">
                            <i class="fa fa-bed me-2" aria-hidden="true"></i>
                            <span>{{'t-beds' | translate}}:
                                {{division.quantity}}</span>
                        </li>
                        <li *ngIf="division.tag == 'bathrooms' && division.quantity != 0">
                            <i class="fa fa-bath me-2" aria-hidden="true"></i>
                            <span>{{'t-baths' | translate}}: {{division.quantity}}</span>
                        </li>
                        <li *ngIf="division.tag == 'garages'">
                            <i class="fa fa-car me-2" aria-hidden="true"></i>
                            <span>{{'t-garages' | translate}}: {{division.quantity}}</span>
                        </li>
                        <li *ngIf="division.tag == 'kitchens' && division.quantity != 0">
                            <i class="fa fa-columns me-2" aria-hidden="true"></i>
                            <span>{{'t-kitchens' | translate}}: {{division.quantity}}</span>
                        </li>

                    </ng-container>

                </ul>
            </div>
            <div class="content-pdf position-relative">
                <div class="sepbottom"></div>
                <div class="qr-code-box d-grid">
                    <span class="qrtext">
                        Mais detalhes no Qr code ou em
                        <br>
                        <a [href]="qrCodeLink">{{qrCodeLink}}</a>
                    </span>
                    <span *ngIf="qrCodeLink">
                        <qrcode [qrdata]="qrCodeLink"></qrcode>
                    </span>
                </div>
                <div class="bottom-box">

                    Powered by Imodigi

                </div>
            </div>
        </div>
    </div>
</div>