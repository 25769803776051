import { Component, Input, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-property-print',
  templateUrl: './property-print.component.html',
  styleUrls: ['./property-print.component.css']
})
export class PropertyPrintComponent implements OnInit {

  @Input() property: any;
  @Input() entityData: any;
  @Input() qrCodeLink: any;

  constructor(private eventService: EventService) { }

  ngOnInit(): void {
    this.eventService.subscribe('open-print', data => {
      this.property = data.property;
      this.qrCodeLink = data.link;
      setTimeout(() => {
        window.print();
      }, 600)
    });
  }

  priceFormat(propertyPrice: any) {
    let price = propertyPrice.toString().split('.');
    const spaces = (price: any) => String(price)
      .replace(
        /(?!^)(?=(?:\d{3})+$)/g,
        ' '
      );
    const price1 = spaces(price[0]);
    if (price.length > 1)
      return price1 + ',' + price[1];

    return price1;
  }

}
